const Paydata = [
    {
        id: 1,
        program: "Online Program",
        price: "₦120,000",
        duration: "3 Months",
    },
    {
        id: 2,
        program: "LevelUp Program",
        price: "₦150,000",
        duration: "7 weeks",
    },
    {
        id: 3,
        program: "Executive Program",
        price: "₦300,000",
        duration: "6 Weeks",
    }
]

export default Paydata